const CommonAccessibility = {
  init: function () {
    this.enterClickDataAttr();
    this.removeTabindexInsideDataAttr();
    this.removeTabindexDataAttr();
  },

  enterClickDataAttr: function () {
    let els = document.querySelectorAll('[data-enter-click-js]');
    if (!els.length) return;

    els.forEach((el) => {
      el.addEventListener('keypress', function (event) {
        if (event.key === 'Enter' || event.keyCode === 13) {
          el.click();
        }
      });
    });
  },

  removeTabindexInsideDataAttr: function () {
    let els = document.querySelectorAll('[data-remove-tabindex-inside-js]');
    if (!els.length) return;

    els.forEach((el) => {
      let insideEls = el.querySelectorAll('a', 'button', 'input');
      if (!insideEls.length) return;

      insideEls.forEach((insideEl) => {
        this.removeTabindex(insideEl);
      });
    });
  },

  removeTabindexDataAttr: function () {
    let els = document.querySelectorAll('[data-remove-tabindex-js]');
    if (!els.length) return;

    els.forEach((el) => {
      this.removeTabindex(el);
    });
  },

  removeTabindex: function (el) {
    if (!el) return;

    el.setAttribute('tabindex', -1);
  },
};

export default CommonAccessibility;
