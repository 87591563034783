import AOS from './utils/aos'
import Header from './parts/layout/header';
import AnchorsScroll from './parts/components/anchor-scroll';
import CommonAccessibility from './parts/components/accessibility';
import AppHeight from './parts/components/app-height';

window.addEventListener('load', () => {
  AnchorsScroll.init();
  Header.init();
  CommonAccessibility.init();
  AppHeight.init();
  AOS.init();
});

window.addEventListener('resize', () => {
  if (window.innerWidth > 1023) {
    document.querySelector("html").classList.remove("overflowHidden");
    document.querySelector("body").classList.remove("overflowHidden");
    document.querySelector("body").classList.remove("navigationOpen");
  }
});
