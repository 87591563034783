/* eslint-disable */


const Header = {
  init: function () {
    this.navigationSubNavs();
    this.mobileNav();
    this.hideMenu();
    this.activeMenuElement();
  },


  /*todo: refactoring*/
  navigationSubNavs: function () {
    const triggers = document.querySelectorAll('[data-sub-nav-trigger-js]');
    if (!triggers || triggers.length === 0) return;

    const self = this;

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const parent = trigger.parentNode;
        const currentSubNav = parent.querySelector('[data-sub-nav]');

        this.checkFocus(currentSubNav);
        if (parent.classList.contains('is-active')) {
          this.closeDesktopSubNav(parent, currentSubNav);
          document.removeEventListener('click', outsideClickHandler);
        } else {
          this.openDesktopSubNav(parent, currentSubNav);
          document.addEventListener('click', outsideClickHandler);
        }

        function outsideClickHandler(event) {
          if (!parent.contains(event.target)) {
            self.closeDesktopSubNav(parent, currentSubNav);
            document.removeEventListener('click', outsideClickHandler);
          }
        }
      })
    })
  },

  closeDesktopSubNav: function(parent, currentSubNav) {
    let currentTrigger = parent.querySelector('[data-sub-nav-trigger-js]');

    parent.classList.remove('is-active');

    currentTrigger.focus();

    this.removeElsFromTabindex(currentSubNav);
  },

  openDesktopSubNav: function(parent, currentSubNav) {
    parent.classList.add('is-active');

    let firstFocusableElement = currentSubNav.querySelector('a');
    firstFocusableElement.focus();
    this.addElsToTabindex(currentSubNav);
  },

  addElsToTabindex: function (currentSubNav) {
    if (!currentSubNav) return;

    let allFocusableElements = currentSubNav.querySelectorAll('a');

    allFocusableElements.forEach((el) => {
      el.setAttribute('tabindex', 0);
    });
  },

  removeElsFromTabindex: function (currentSubNav) {
    if (!currentSubNav) return;

    let allFocusableElements = currentSubNav.querySelectorAll('a');

    allFocusableElements.forEach((el) => {
      el.setAttribute('tabindex', -1);
    });
  },

  checkFocus: function (currentSubNav) {
    if (!currentSubNav) return;

    let self = this;
    let allFocusableElements = currentSubNav.querySelectorAll('a');
    let parent = currentSubNav.parentNode

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Tab' || event.keyCode === 9) {
        if (parent.classList.contains('is-active')) {
          checkActiveLinks();
        }
      }
    });

    function checkActiveLinks() {
      requestAnimationFrame(function () {
        let isAnyFocused = Array.from(allFocusableElements).some(function (el) {
          return el === document.activeElement;
        });

        if (!isAnyFocused) {
          self.closeAllSubNavs();
        }
      });
    }
  },

  closeAllSubNavs: function () {
    let subNavs = document.querySelectorAll(`.header__nav-item.is-active`);
    if (subNavs.length) {
      subNavs.forEach((parent) => {
        let currentSubNav = parent.querySelector('[data-sub-nav]');
        this.closeDesktopSubNav(parent, currentSubNav);
      });
    }
  },

  activeMenuElement: function () {
    let currentUrl = window.location.href;

    let menuLinks = document.querySelectorAll('.header__nav-item a');

    menuLinks.forEach(function (link) {
      if (link.href === currentUrl) {
        link.classList.add('active');
      }
    });
  },

  /*----------------*/

  mobileNav: function () {
    const body = document.querySelector("body");
    const openNavTrigger = document.querySelector("[data-open-nav-js]");
    if (!openNavTrigger) return;

    openNavTrigger.addEventListener("click", () => {
      if (body.classList.contains("navigationOpen")) {
        this.closeMobileNav();
      } else {
        this.openMobileNav();
      }
    });
  },

  openMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.add("overflowHidden");
    body.classList.add("overflowHidden");
    body.classList.add("navigationOpen");
  },

  closeMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.remove("overflowHidden");
    body.classList.remove("overflowHidden");
    body.classList.remove("navigationOpen");

    window.onresize = function(event) {
      if (window.innerWidth > 1023) {

      }
    };

    window.addEventListener("resize", function() {
      if (window.innerWidth < 500) {
        console.log('here')
        html.classList.remove("overflowHidden");
        body.classList.remove("overflowHidden");
        body.classList.remove("navigationOpen");
      }
    });
  },

  hideMenu: function () {
    let body = document.body

    let prev = 0;
    let nav = document.querySelector(".header__inner");

    window.addEventListener("scroll", function () {
      if(!body.classList.contains('navigationOpen')) {

        let scrollTop = window.pageYOffset || 0;

        scrollTop > prev ? nav.classList.add("hidden") : nav.classList.remove("hidden")

        window.pageYOffset > 20 ? nav.classList.add("scrolled") : nav.classList.remove("scrolled")

        prev = scrollTop;
      }
    });
  },

  /*----------------*/

};

export default Header;
