import {gsap} from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const anchorsScroll = {

  init: function () {

    function smoothScroll(target) {
      gsap.to(window, {
        duration: 0.7,
        scrollTo: {
          y: target,
          offsetY: 80,
          autoKill: false,
        },
        ease: "ease.inOut",
      });
    }

    let anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(function (anchor) {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        let target = document.querySelector(this.getAttribute("href"));
        let target2 = document.querySelector(`[data-anchor='${this.getAttribute("href")}']`);
        if (target) {
          smoothScroll(target);
        } else if (target2){
          smoothScroll(target2);
        } else {
          let linkTarget = this.getAttribute("target") ? this.getAttribute("target") : '_self'
          window.open(this.getAttribute("href"), linkTarget);
        }
      });
    });
  },
}

export default anchorsScroll;
